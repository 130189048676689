<template>
  <b-card>

    <h4 v-if="currentObject.event_name" class="mb-2">
      Event Email <a :href="'/events/' + currentObject.event_id + '/view'">({{ currentObject.event_name }})</a>
    </h4>
    
    <div class="row">
      <div class="col-sm-6">
        <div class="float-left">
          <b-button-group>
            <b-dropdown variant="primary" right split text="Actions" v-if="$can('add', 'emails') || $can('view', 'emails')">
              <b-dropdown-item @click="add()" v-if="$can('add', 'emails')" :disabled="isLoading">
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  /><span>Add</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </div>
      </div>
      <div class="col-sm-6 pt-1 pt-md-0 pb-md-0">
        <div class="custom-search float-right">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                name="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

      </div>
    </div>


    <!-- table -->
    <div v-if="!rows || !allRows || isLoadingTable || !currentObject.event_name" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <div class="event-email">
      <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: true,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Subject -->
        <span
          v-if="props.column.field === 'subject'"
          class="text-nowrap"
        > 
        {{ (props.row.subject) ? props.row.subject : '-' }}
        </span>

        <!-- Column: Send at -->
        <span
          v-if="props.column.field === 'sent_at'"
          class="text-nowrap"
        >
        {{ (props.row.sent_at) ? formatDateReverse(props.row.sent_at) : '-' }}
        </span>

        <!-- Column: Sent Status -->
        <span
          v-if="props.column.field === 'sent_status'"
          class="text-nowrap"
        > {{ (props.row.sent_status == 1) ? 'Done' : 'Not Yet' }}
        </span>

        <!-- Column: Number Email Sent -->
        <span
          v-if="props.column.field === 'total_sent'"
          class="text-nowrap"
        > <a @click="sentEmailModal(props.row.id, props.row.subject)"><u>{{ props.row.total_sent }} Email</u></a>
        </span>

        <!-- Column: is confirm email -->
        <span
          v-if="props.column.field === 'is_confirm_email'"
          class="text-nowrap"
        > {{ (props.row.is_confirm_email == 1) ? 'Yes' : 'No' }}
        </span>

       <!-- Column: is decline email -->
       <span
          v-if="props.column.field === 'is_reject_email'"
          class="text-nowrap"
        > {{ (props.row.is_reject_email == 1) ? 'Yes' : 'No' }}
        </span>

        <!-- Column: is participant only email -->
        <span
          v-if="props.column.field === 'is_participant_only'"
          class="text-nowrap"
        > {{ (props.row.is_participant_only == 1) ? 'Yes' : 'No' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="sendTestModal(props.row.id, props.row.subject)" v-if="$can('edit', 'emails')">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Send Test Email</span>
              </b-dropdown-item>
              <b-dropdown-item @click="sendModal(props.row.id, props.row.subject)" v-if="$can('edit', 'emails') && props.row.is_confirm_email==0 && props.row.is_reject_email==0">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Send</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editModal(props.row)" v-if="$can('edit', 'emails')">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="detailModal(props.row)" v-if="$can('view', 'emails')">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Preview</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.id, props.row.subject)" v-if="$can('delete', 'emails') && props.row.is_confirm_email==0 && props.row.is_reject_email==0 && props.row.is_participant_only==0">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          <!-- {{ props.formattedRow[props.column.field] }} -->
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </div>

    <div class="mt-2">
      <div style="float: left">
        <b-button size="md" variant="outline-primary" @click="viewAllEvents()" v-if="$can('view', 'events')">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Back</span>
        </b-button>
      </div>
    </div>

  <!-- Modals add -->

    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormModal"
      data-bs-focus="false"
    >
      <template #modal-header="{}">
        <h5>Add {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm">

        <b-form-group>
          <label for="email-type">Email Type:</label>
          <!-- <v-select
                v-model="currentObject.emailType"
                :options="emailTypeOption"
                :reduce="val => val.value"
                :clearable="false"
                input-id="email-type"
                placeholder="Select email type"
              /> -->
          <b-form-input
            v-model="currentObject.emailType"
            name="text"
            class="d-inline-block"
            readonly
          />
        </b-form-group>

        <b-form-group>
          <label for="subject">Subject:</label>
          <b-form-input
            v-model="currentObject.subject"
            placeholder="Email Subject"
            name="text"
            class="d-inline-block"
          />
        </b-form-group>

        <b-form-group>
          <label for="content">Content:</label>
          <b-overlay :show="isContentLoading" rounded="sm">
            <vue-editor 
              ref="editor-create" 
              @focus="onFocusCreate"
              v-model="currentObject.content"
              useCustomImageHandler
              @image-added="handleImageAdded"
              :id="currentObject.content"
              :aria-hidden="isContentLoading ? 'true' : null"
            />  
            <br>
            <b>tag list:</b>  
            <br>   
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[logo]')">[logo]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[header]')">[header]</b-button>  
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[salutation]')">[salutation]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[firstname]')">[firstname]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[lastname]')">[lastname]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[fullname]')">[fullname]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[company]')">[company]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[email]')">[email]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[event_name]')">[event_name]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[event_date]')">[event_date]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[event_place]')">[event_place]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[calendar]')">[calendar]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagCreate('[qrcode]')">[qrcode]</b-button>       

          </b-overlay>
        </b-form-group>

        <b-form-group>
          <label for="calender-content">Calender Content (type [NL] for new line):</label>
          <b-form-input
            v-model="currentObject.calenderContent"
            placeholder="Content for calender. type [NL] for new line / enter"
            name="text"
            class="d-inline-block"
          />
        </b-form-group>

        <b-form-group>
          <label for="send-date">Send Date:</label>
          <flat-pickr
            v-model="currentObject.sendDate"
            class="form-control"
            :config="dateConfig"
            placeholder="Pick the date"
          />
        </b-form-group>

      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="primary" @click="save()" :disabled="!isValid() || isLoadingForm">
          {{ (!isLoadingForm) ? "Save" : "Loading..." }}
        </b-button>
      </template>


    </b-modal>

  <!-- Modals delete-->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ pageName }}</h5>
      </template>

      <p>
        Are you sure you want to delete email with subject <b>"{{ currentObject.subject }}"</b>?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <!-- Modals edit-->
    <b-modal
      id="modal-edit"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showEditModal"
    >
      
    <template #modal-header="{}">
        <h5>Edit {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm">

        <b-form-group>
          <label for="email-type">Email Type:</label>
          <!-- <v-select
                v-model="currentObject.emailType"
                :options="emailTypeOption"
                :reduce="val => val.value"
                :clearable="false"
                input-id="email-type"
                placeholder="Select email type"
              /> -->
          <b-form-input
            v-model="currentObject.emailType"
            name="text"
            class="d-inline-block"
            readonly
          />
        </b-form-group>

        <b-form-group>
          <label for="subject">Subject:</label>
          <b-form-input
            v-model="currentObject.subject"
            placeholder="Email Subject"
            name="text"
            class="d-inline-block"
          />
        </b-form-group>

        <b-form-group>
          <label for="content">Content:</label>
          <b-overlay :show="isContentLoading" rounded="sm">
            <vue-editor 
              ref="editor-edit" 
              @focus="onFocusEdit"
              v-model="currentObject.content"
              useCustomImageHandler
              @image-added="handleImageAdded"
              :id="currentObject.content"
              :aria-hidden="isContentLoading ? 'true' : null"
            />
            <br>
            <b>tag list:</b>  
            <br>   
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[logo]')">[logo]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[header]')">[header]</b-button>  
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[salutation]')">[salutation]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[firstname]')">[firstname]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[lastname]')">[lastname]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[fullname]')">[fullname]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[company]')">[company]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[email]')">[email]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[event_name]')">[event_name]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[event_date]')">[event_date]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[event_place]')">[event_place]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[calendar]')">[calendar]</b-button>       
            <b-button style="margin: 5px" variant="outline-secondary" @click="addTagEdit('[qrcode]')">[qrcode]</b-button>       
              
          </b-overlay>
        </b-form-group>

        <b-form-group>
          <label for="calender-content">Calender Content (type [NL] for new line):</label>
          <b-form-input
            v-model="currentObject.calenderContent"
            placeholder="Content for calender. type [NL] for new line / enter"
            name="text"
            class="d-inline-block"
          />
        </b-form-group>

        <b-form-group>
          <label for="send-date">Send Date:</label>
          <flat-pickr
            v-model="currentObject.sendDate"
            class="form-control"
            :config="dateConfig"
            placeholder="Pick the date"
          />
        </b-form-group>

      </b-form>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="primary" @click="editEmail()">
          {{ (!isLoadingForm) ? "Save" : "Loading..." }}
          </b-button>
        </div>
      </template>


    </b-modal>

    <!-- Modals detail-->

    <b-modal
      id="modal-detail"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDetailModal"
    >
      <template #modal-header="{}">
        <h5>Email Detail</h5>
      </template>
      
      <b-row>
        <b-col cols="12">
          <h5>Subject</h5>
          <p>{{ currentObject.subject }}</p>

          <h5>Email Content</h5>
          <p class="body-content" v-html="currentObject.displayContent" style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;"></p>

          <h5>Email Calendar Content</h5>
          <p class="body-content" v-html="currentObject.calenderContent ? currentObject.calenderContent : '-'" style="border: 1px solid #b4b7bd; padding: 10px;overflow: auto; width:100%; height: 100px;"></p>
          
          <h5>Send Date</h5>
          <p>{{ currentObject.sendDate }}</p>

          <h5>Email Type</h5>
          <p>{{ currentObject.emailType }}</p>
        
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="primary" @click="sendTestModal(currentObject.event_email_id,currentObject.subject)" v-if="$can('edit', 'emails')">
          Send Test Email
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="primary" @click="sendModal(currentObject.event_email_id,currentObject.subject)" v-if="$can('edit', 'emails') && (currentObject.emailType=='Reminder Email' || currentObject.emailType=='Thank You Email')">
          Send Email
          </b-button>
        </div>
      </template>


    </b-modal>


    <!-- Modals Send email-->
    <b-modal
      id="modal-send"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showSendModal"
    >
      <template #modal-header="{}">
        <h5>Send Email</h5>
      </template>

      <div v-if="isLoadingSentEmail" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-row  v-if="!isLoadingSentEmail">
        <b-col cols="12">
          <h5>Subject</h5>
          <p>{{ currentObject.subject }}</p>

          <h5>Info!</h5>
          <p>This action will send email to these person:</p>

          <div style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;">
            <!-- List of names -->
            <div v-for="(item, index) in emails" :key="index">
              {{ item }}
            </div>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="primary" @click="sendEmail()" :disabled="isLoadingSentEmail">
          Send Email
          </b-button>
        </div>
      </template>
    </b-modal>


    <!-- Modals send test email-->
    <b-modal
      id="modal-send-test"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showSendTestModal"
    >

      <template #modal-header="{}">
        <h5>Send Test Email</h5>
      </template>

      <div v-if="isLoadingSentTestEmail" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-row  v-if="!isLoadingSentTestEmail">
        <b-col cols="12">
          <h5>Subject</h5>
          <p>{{ currentObject.subject }}</p>

          <h5>Info!</h5>
          <p>This action will send test email to these person:</p>

          <div style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;">
            <!-- List of names -->
            <div v-for="(item, index) in emails" :key="index">
              {{ item }}
            </div>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="primary" @click="sendTestEmail()" :disabled="isLoadingSentTestEmail">
          Send Test Email
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Modals list already sent email-->
    <b-modal
      id="modal-send-test"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showAlreadySendModal"
    >

      <template #modal-header="{}">
        <h5>List already sent email</h5>
      </template>

      <div v-if="isLoadingSentEmail" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-row  v-if="!isLoadingSentEmail">
        <b-col cols="12">
          <h5>Subject</h5>
          <p>{{ currentObject.subject }}</p>

          <h5>Info!</h5>
          <p>These are list of emails that already sent:</p>

          <div style="border: 1px solid #b4b7bd; padding: 10px; overflow: auto; width:100%; height: 250px;">
            <!-- List of names -->
            <div v-for="(item, index) in emails" :key="index">
              {{ item }}
            </div>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
      </template>
    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol, BButtonGroup, BFormFile, BAlert, BFormCheckbox, BBadge,BOverlay
} from 'bootstrap-vue'
import router from '@/router'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx'
import { saveAs } from "file-saver"
import JSZip from "jszip"
import axios from "axios"
import { VueEditor } from "vue2-editor"
import flatPickr from 'vue-flatpickr-component'



export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    BCard,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    BFormFile,
    XLSX,
    BAlert,
    BFormCheckbox,
    BBadge,
    saveAs,
    JSZip,
    axios,
    VueEditor,
    flatPickr,
    BOverlay
  },
  data() {
    return {
      isLoading: false,
      users: null,
      pageLength: 10,
      columns: [
        {
          label: 'No',
          field: 'row_number'
        },
        {
          label: 'Subject',
          field: 'subject'
        },
        {
          label: 'Target Send Date',
          field: 'sent_at',
        },
        // {
        //   label: 'Send Status',
        //   field: 'sent_status',
        // },
        {
          label: 'Number Email Sent',
          field: 'total_sent',
        },
        {
          label: 'Is Accept Email',
          field: 'is_confirm_email',
        },
        {
          label: 'Is Declined Email',
          field: 'is_reject_email',
        },
        {
          label: 'Send To Participant Only',
          field: 'is_participant_only',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      allRows: [],
      emails: [],
      searchTerm: '',
      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      isLoadingSentEmail: false,
      isLoadingSentTestEmail: false,
      isLoadingSentEmail: false,
      isContentLoading: false,
      showDeleteModal: false,
      showDetailModal: false,
      showEditModal: false,
      showSendModal: false,
      showSendTestModal: false,
      showFormModal: false,
      showAlreadySendModal: false,
      pageName: "Event Email",
      currentObject: {
        user_id: -1,
        event_id: -1,
        event_email_id: -1,
        subject: "",
        content: "",
        displayContent: "",
        calenderContent: "",
        calenderContentAltered: "",
        sendDate:"",
        sendDateDB:"",
        emailType:""
      },
      currentUserObject: null,
      currentFields: null,
      dateConfig: {
        altFormat: 'Y-m-d', 
        dateFormat: 'd-m-Y', 
        minDate: null, 
        enableTime: false,
        static : true,
        wrap: true,
      },
      emailTypeOption: [
        {
          label: "Reminder Email",
          value: "reminder"
        }, 
        {
          label: "Accept Email",
          value: "accept"
        }, 
        {
          label: "Declined Email",
          value: "decline"
        }, 
        {
          label: "Thank You Email",
          value: "thankyou"
        }
      ],
      quill_create: null,
      quill_edit: null,
    }
  },
  computed: {
  },
  methods: {
    onFocusCreate(quill) {
      this.quill_create = quill
    },
    async addTagCreate(tag){
      const Editor = this.quill_create
      const range = Editor.getSelection()
      const cursorLocation = await range.index
      Editor.insertText(cursorLocation, tag)
    },
    onFocusEdit(quill) {
      this.quill_edit = quill
    },
    async addTagEdit(tag){
      const Editor = this.quill_edit
      const range = Editor.getSelection()
      const cursorLocation = await range.index
      Editor.insertText(cursorLocation, tag)
    },
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY')
    },
    viewAllEvents() {
      this.$router.push('/events')
    },
    add() {
      this.showFormModal = true

      this.currentObject.event_id = router.currentRoute.params.id
      this.currentObject.subject = ''
      this.currentObject.content = ''
      this.currentObject.calenderContent = ''
      this.currentObject.calenderContentAltered = ''
      this.currentObject.sendDate = ''
      this.currentObject.sendDateDB = ''
      this.currentObject.emailType = 'Reminder Email'
    },
    removeModal(id, subject) {      
      this.showDeleteModal = true

      this.currentObject.event_email_id = id
      this.currentObject.subject = subject
      this.currentObject.event_id = router.currentRoute.params.id

    },
    detailModal(rowData) {        
        this.currentObject.event_email_id = rowData.id
        this.currentObject.subject = rowData.subject
        this.currentObject.content = rowData.content
        this.currentObject.displayContent = rowData.display_content
        this.currentObject.calenderContent = rowData.calendar_body ? rowData.calendar_body.replace(/%0A/gi, '<br>') : ""
        this.currentObject.sendDate = moment(String(rowData.sent_at), 'YYYY-MM-DD').format('DD-MM-YYYY')
        if(rowData.is_confirm_email == 1)
          this.currentObject.emailType = "Accept Email"
        else if(rowData.is_reject_email == 1)
          this.currentObject.emailType = "Declined Email"
        else if(rowData.is_participant_only == 1)
          this.currentObject.emailType = "Thank You Email"
        else
        this.currentObject.emailType = "Reminder Email"

        this.showDetailModal = true
    },
    editModal(rowData) {        
        this.currentObject.event_email_id = rowData.id
        this.currentObject.subject = rowData.subject
        this.currentObject.content = rowData.content
        this.currentObject.calenderContent = rowData.calendar_body ? rowData.calendar_body.replace(/%0A/gi, '[NL]') : ""
        this.currentObject.sendDate = rowData.sent_at
        if(rowData.is_confirm_email == 1)
          this.currentObject.emailType = "Accept Email"
        else if(rowData.is_reject_email == 1)
          this.currentObject.emailType = "Declined Email"
        else if(rowData.is_participant_only == 1)
          this.currentObject.emailType = "Thank You Email"
        else
        this.currentObject.emailType = "Reminder Email"

        this.showEditModal = true
    },
    sentEmailModal(id, subject) {

      this.currentObject.event_email_id = id
      this.currentObject.subject = subject

      //display modal
      this.showAlreadySendModal = true

      //run loading
      this.isLoadingSentEmail = true

      //load data
      this.emails = []
      this.$http.get('/admin/v1/email/list-sent/' + this.currentObject.event_email_id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
            this.emails = currentData;
            
          }

          this.isLoadingSentEmail = false

      })



      },
    sendTestModal(id, subject) {

      this.currentObject.event_email_id = id
      this.currentObject.subject = subject

      //display modal
      this.showDetailModal = false
      this.showSendTestModal = true

      //run loading
      this.isLoadingSentTestEmail = true

      //load data
      this.emails = []
      this.$http.get('/admin/v1/email/list-tester/' + this.currentObject.event_email_id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
            this.emails = currentData;
            
          }

          this.isLoadingSentTestEmail = false

     })

      

    },
    sendModal(id, subject) {
      
      this.currentObject.event_email_id = id
      this.currentObject.subject = subject
      
      //display modal
      this.showDetailModal = false
      this.showSendModal = true

      //run loading
      this.isLoadingSentEmail = true

      //load data
      this.emails = []
      this.$http.get('/admin/v1/email/list/' + this.currentObject.event_email_id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
            this.emails = currentData;
            
          }

          this.isLoadingSentEmail = false

     })

      
    },
    sendEmail(){
      //show loading
      this.isLoadingSentEmail = true

      //check id
      if(this.currentObject.event_email_id == null || this.currentObject.event_email_id == ""){
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'Failed to send email',
            },
          })
      }

      //send email
      this.$http.post('/admin/v1/email/send/' + this.currentObject.event_email_id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showSendModal = false

          this.isLoadingSentEmail = false

          this.fetchData()
      })
    },
    sendTestEmail(){
      //show loading
      this.isLoadingSentTestEmail = true

      //check id
      if(this.currentObject.event_email_id == null || this.currentObject.event_email_id == ""){
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'Failed to send test email',
            },
          })
      }

      //send email
      this.$http.post('/admin/v1/email/test/' + this.currentObject.event_email_id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.isLoadingSentTestEmail = false

          this.showSendTestModal = false

          this.fetchData()
      })
    },
    save(){
      this.isLoadingForm = true

      //replace [NL] to ascci %0A
      this.currentObject.calenderContentAltered = this.currentObject.calenderContent.replace(/\[NL\]/gi, '%0A').replace(/\[nl\]/gi, '%0A').replace(/\[nL\]/gi, '%0A').replace(/\[Nl\]/gi, '%0A')
      this.currentObject.sendDateDB = moment(String(this.currentObject.sendDate), 'DD-MM-YYYY').format('YYYY-MM-DD')
      //console.log(this.currentObject)
      
      //save
      this.$http.post('/admin/v1/email/' + this.currentObject.event_id, {
        subject : this.currentObject.subject,
        content : this.currentObject.content,
        send_date : this.currentObject.sendDateDB,
        content_calendar : this.currentObject.calenderContentAltered,
        email_type : this.currentObject.emailType
      })
      .then(res => {
        const data = res.data
        const message = data.message

        if(!data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: message,
            },
          })
        }
        else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Success`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: message,
            },
          })
        }

        //remove loading
        this.showFormModal = false
        this.isLoadingForm = false

        //fetch data
        this.fetchData()
      })
    },
    editEmail(){
      this.isLoadingForm = true

      //replace [NL] to ascci %0A
      this.currentObject.calenderContentAltered = this.currentObject.calenderContent.replace(/\[NL\]/gi, '%0A').replace(/\[nl\]/gi, '%0A').replace(/\[nL\]/gi, '%0A').replace(/\[Nl\]/gi, '%0A')
      this.currentObject.sendDateDB = moment(String(this.currentObject.sendDate), 'DD-MM-YYYY').format('YYYY-MM-DD')
      //console.log(this.currentObject)

      //save
      this.$http.put('/admin/v1/email/' + this.currentObject.event_email_id, {
        subject : this.currentObject.subject,
        content : this.currentObject.content,
        send_date : this.currentObject.sendDateDB,
        content_calendar : this.currentObject.calenderContentAltered,
        email_type : this.currentObject.emailType
      })
      .then(res => {
        const data = res.data
        const message = data.message

        if(!data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: message,
            },
          })
        }
        else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Success`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: message,
            },
          })
        }

        //remove loading
        this.showEditModal = false
        this.isLoadingForm = false

        //fetch data
        this.fetchData()
      })

    },
    remove() {
      this.isLoadingDelete = true

      this.$http.delete('/admin/v1/email/' + this.currentObject.event_email_id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.isContentLoading = true
      // this.currentObject.questions[this.currentQuestionIndex].isLoading = true

      const requestData = new FormData()

      requestData.append('image', file);

      this.$http.post('/admin/v1/upload_image', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const data = res.data
              const currentData = data.data

              const url = currentData.url

              this.isContentLoading = false
              // this.currentObject.questions[this.currentQuestionIndex].isLoading = false

              Editor.insertEmbed(cursorLocation, "image", url)
              
              resetUploader()

            }

            this.isContentLoading = false
            // this.currentObject.questions[this.currentQuestionIndex].isLoading = false
        })

    },
    fetchData() {
      this.isLoadingTable = true

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      this.$http.get('/admin/v1/email/event/' + this.currentObject.event_id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.allRows = currentData
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
    fetchEvent() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.currentObject.event_name =  currentData.name
          }
     })
    },
    isValid() {
      return this.currentObject.subject !== null && this.currentObject.subject != '' && this.currentObject.content !== null && this.currentObject.content != '' && this.currentObject.sendDate !== null && this.currentObject.sendDate != '' && this.currentObject.emailType !== null && this.currentObject.emailType != ''
    }
  },
  created() {
    this.fetchData()
    this.fetchEvent()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.participant-filter .v-select.vs--single.vs--searchable {
  width: 240px;
}

.event-participant-table .vgt-responsive {
  min-height: 350px;
}
</style>
